.App {
  padding-top: 2rem;
  text-align: center;
  font-family: 'Averia Serif Libre', serif;
  font-size: 1.5rem;
  color: #492E1E;
}

.App-title-card {
  max-width: 95vw;
}

.App-phrase,
.App-dedication {
  padding: 2rem;
}

.App-dedication {
  font-size: 1rem;
  color: #E06C5F;
}

.App-button {
  color: #E06C5F;
  background-color: #FDFAE5;
  border: 2px solid #E06C5F;
  font-family: adrianna-extended,sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  padding: 1rem;
  border-radius: 5px;
}

.App-button:hover {
  color: #FDFAE5;
  background-color: #e68075;
  border: 2px solid #e68075;
  transform: translateY(1px);
}

.App-credits {
  font-size: .9rem;
  padding-top: 3rem;
}

.App-credits a {
  text-decoration: none;
  color: #E06C5F;
}


